import * as Yup from "yup";

export const passwordSchema = Yup.string()
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
    "Include at least one number, one uppercase letter and one special character"
  )
  .min(8, "Password needs to contain at least 8 characters")
  .required();

export const passwordRepeatSchema = Yup.string()
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
    "Include at least one number, one uppercase letter and one special character"
  )
  .min(8, "Password needs to contain at least 8 characters")
  .oneOf([Yup.ref("password")], "Passwords do not match")
  .required();
